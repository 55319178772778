.container-404 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url("../img/glitch.png");
  overflow: hidden;
}
.container-cyber {
  width: 70%;
  user-select: none;
  position: relative;
}
.container-cyber:nth-child(1) h2 {
  transform: rotate(-15deg);
  top: 8%;
}
.container-cyber:nth-child(1) {
  margin-bottom: 8%;
}
.container-cyber a {
  text-decoration: none;
}
.container-cyber a:visited {
  color: #fff;
}
.container-cyber a::after {
  content: "Yes";
  position: absolute;
  z-index: 99;
  background-color: black;
  left: 0;
  width: 0%;
  visibility: hidden;
  /* 1 o los dos, ser o no ser? (visibility/opacity */
  opacity: 0;
  transition: all 0.3s ease-out;
  font-size: 3em;
  cursor: none;
  animation: paths 5s step-end infinite, opacity 5s step-end infinite,
    font 8s step-end infinite, glitch 5s infinite;
}
.container-cyber a:hover::after {
  visibility: visible;
  opacity: 1;
  width: 100%;
}
.container-cyber a:hover span {
  opacity: 0;
  visibility: hidden;
}
.container-cyber p,
.container-cyber a {
  font-weight: bold;
  color: #fff;
}
.container-cyber p {
  animation: glitch 5s infinite;
  font-size: 4em;
  margin-bottom: 4%;
}
.container-cyber a span {
  font-size: 3em;
}

/* Glitch effect 01 (text shadow color)...change? (RGB) */
@keyframes glitch {
  0% {
    text-shadow: 1px 0 0 red, -1px 0 0 blue;
  }
  4% {
    text-shadow: 1px 0 0 red, -1px 0 0 blue;
  }
  5% {
    text-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  10% {
    text-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  11% {
    text-shadow: 0.5px 0 0 red, -0.5px 0 0 green;
  }
  15% {
    text-shadow: 0.5px 0 0 red, -0.5px 0 0 green;
  }
  16% {
    text-shadow: -1px 0 0 red, 1px 0 0 green;
  }
  20% {
    text-shadow: -1px 0 0 red, 1px 0 0 green;
  }
  21% {
    text-shadow: 0.7px 0 0 blue, -0.7px 0 0 green;
  }
  30% {
    text-shadow: 0.7px 0 0 blue, -0.7px 0 0 green;
  }
  31% {
    text-shadow: -1px 0 0 blue, 1px 0 0 green;
  }
  40% {
    text-shadow: -1px 0 0 blue, 1px 0 0 green;
  }
  41% {
    text-shadow: 50px 0 0 blue, -50px 0 0 green;
  }
  42% {
    text-shadow: 0 0 0 blue, 0 0 0 green;
  }
  43% {
    text-shadow: 0.5px 0 0 red, -0.5px 0 0 green;
  }
  46% {
    text-shadow: 0.5px 0 0 red, -0.5px 0 0 green;
  }
  47% {
    text-shadow: -1px 0 0 red, 1px 0 0 green;
  }
  50% {
    text-shadow: -1px 0 0 red, 1px 0 0 green;
  }
  51% {
    text-shadow: 1px 0 0 red, -1px 0 0 blue;
  }
  55% {
    text-shadow: 1px 0 0 red, -1px 0 0 blue;
  }
  56% {
    text-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  60% {
    text-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  61% {
    text-shadow: 30px 0 0 red, -30px 0 0 green;
  }
  62% {
    text-shadow: 0 0 0 red, 0 0 0 green;
  }
  63% {
    text-shadow: 0.5px 0 0 red, -0.5px 0 0 blue;
  }
  66% {
    text-shadow: 0.5px 0 0 red, -0.5px 0 0 blue;
  }
  67% {
    text-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  70% {
    text-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  71% {
    text-shadow: 70px 0 0 red, -70px 0 0 blue;
  }
  72% {
    text-shadow: 0 0 0 red, 0 0 0 blue;
  }
  73% {
    text-shadow: 1px 0 0 red, -1px 0 0 blue;
  }
  76% {
    text-shadow: 1px 0 0 red, -1px 0 0 blue;
  }
  77% {
    text-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  80% {
    text-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  81% {
    text-shadow: 0.5px 0 0 red, -0.5px 0 0 green;
  }
  85% {
    text-shadow: 0.5px 0 0 red, -0.5px 0 0 green;
  }
  86% {
    text-shadow: -1px 0 0 red, 1px 0 0 green;
  }
  90% {
    text-shadow: -1px 0 0 red, 1px 0 0 green;
  }
  91% {
    text-shadow: 60px 0 0 green, -60px 0 0 blue;
  }
  92% {
    text-shadow: 0 0 0 green, 0 0 0 blue;
  }
  92% {
    text-shadow: 0.7px 0 0 blue, -0.7px 0 0 green;
  }
  95% {
    text-shadow: 0.7px 0 0 blue, -0.7px 0 0 green;
  }
  96% {
    text-shadow: -1px 0 0 blue, 1px 0 0 green;
  }
  99% {
    text-shadow: -1px 0 0 blue, 1px 0 0 green;
  }
  100% {
    text-shadow: -1px 0 0 blue, 1px 0 0 green;
  }
}
/* Glitch effect 01 v2 (box shadow color) */
@keyframes glitch-box {
  0% {
    box-shadow: 1px 0 0 red, -1px 0 0 blue;
  }
  4% {
    box-shadow: 1px 0 0 red, -1px 0 0 blue;
  }
  5% {
    box-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  10% {
    box-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  11% {
    box-shadow: 0.5px 0 0 red, -0.5px 0 0 green;
  }
  15% {
    box-shadow: 0.5px 0 0 red, -0.5px 0 0 green;
  }
  16% {
    box-shadow: -1px 0 0 red, 1px 0 0 green;
  }
  20% {
    box-shadow: -1px 0 0 red, 1px 0 0 green;
  }
  21% {
    box-shadow: 0.7px 0 0 blue, -0.7px 0 0 green;
  }
  30% {
    box-shadow: 0.7px 0 0 blue, -0.7px 0 0 green;
  }
  31% {
    box-shadow: -1px 0 0 blue, 1px 0 0 green;
  }
  40% {
    box-shadow: -1px 0 0 blue, 1px 0 0 green;
  }
  41% {
    box-shadow: 5px 5px 0 blue, -5px -5px 0 green;
  }
  42% {
    box-shadow: 0 0 0 blue, 0 0 0 green;
  }
  43% {
    box-shadow: 0.5px 0 0 red, -0.5px 0 0 green;
  }
  46% {
    box-shadow: 0.5px 0 0 red, -0.5px 0 0 green;
  }
  47% {
    box-shadow: -1px 0 0 red, 1px 0 0 green;
  }
  50% {
    box-shadow: -1px 0 0 red, 1px 0 0 green;
  }
  51% {
    box-shadow: 1px 0 0 red, -1px 0 0 blue;
  }
  55% {
    box-shadow: 1px 0 0 red, -1px 0 0 blue;
  }
  56% {
    box-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  60% {
    box-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  61% {
    box-shadow: 3px 3px 0 red, -3px -3px 0 green;
  }
  62% {
    box-shadow: 0 0 0 red, 0 0 0 green;
  }
  63% {
    box-shadow: 0.5px 0 0 red, -0.5px 0 0 blue;
  }
  66% {
    box-shadow: 0.5px 0 0 red, -0.5px 0 0 blue;
  }
  67% {
    box-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  70% {
    box-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  71% {
    box-shadow: 7px 7px 0 red, -7px -7px 0 blue;
  }
  72% {
    box-shadow: 0 0 0 red, 0 0 0 blue;
  }
  73% {
    box-shadow: 1px 0 0 red, -1px 0 0 blue;
  }
  76% {
    box-shadow: 1px 0 0 red, -1px 0 0 blue;
  }
  77% {
    box-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  80% {
    box-shadow: -1px 0 0 red, 1px 0 0 blue;
  }
  81% {
    box-shadow: 0.5px 0 0 red, -0.5px 0 0 green;
  }
  85% {
    box-shadow: 0.5px 0 0 red, -0.5px 0 0 green;
  }
  86% {
    box-shadow: -1px 0 0 red, 1px 0 0 green;
  }
  90% {
    box-shadow: -1px 0 0 red, 1px 0 0 green;
  }
  91% {
    box-shadow: 6px 6px 0 green, -6px -6px 0 blue;
  }
  92% {
    box-shadow: 0 0 0 green, 0 0 0 blue;
  }
  92% {
    box-shadow: 0.7px 0 0 blue, -0.7px 0 0 green;
  }
  95% {
    box-shadow: 0.7px 0 0 blue, -0.7px 0 0 green;
  }
  96% {
    box-shadow: -1px 0 0 blue, 1px 0 0 green;
  }
  99% {
    box-shadow: -1px 0 0 blue, 1px 0 0 green;
  }
  100% {
    box-shadow: -1px 0 0 blue, 1px 0 0 green;
  }
}

.container-cyber h2 {
  font-size: 6em;
  display: inline-block;
  color: #fff;
  letter-spacing: 4px;
  filter: drop-shadow(0 1px 3px);
  z-index: 99;
}
.container-cyber .layers {
  position: relative;
}
.container-cyber .layers::before,
.container-cyber .layers::after {
  content: attr(data-text);
  position: absolute;
  width: 110%;
  z-index: -1;
  font-family: Roboto, sans-serif;
}
.container-cyber .layers::before {
  top: 12px;
  left: 16px;
  color: #e92983;
}
.container-cyber .layers::after {
  top: 6px;
  left: -12px;
  /* color: #1bc7fb; */
  color: var(--secondary-color);
}

/* Glitch effect 02 (mas cariño)*/
@keyframes paths {
  0% {
    clip-path: polygon(
      0% 43%,
      83% 43%,
      83% 22%,
      23% 22%,
      23% 24%,
      91% 24%,
      91% 26%,
      18% 26%,
      18% 83%,
      29% 83%,
      29% 17%,
      41% 17%,
      41% 39%,
      18% 39%,
      18% 82%,
      54% 82%,
      54% 88%,
      19% 88%,
      19% 4%,
      39% 4%,
      39% 14%,
      76% 14%,
      76% 52%,
      23% 52%,
      23% 35%,
      19% 35%,
      19% 8%,
      36% 8%,
      36% 31%,
      73% 31%,
      73% 16%,
      1% 16%,
      1% 56%,
      50% 56%,
      50% 8%
    );
  }

  5% {
    clip-path: polygon(
      0% 29%,
      44% 29%,
      44% 83%,
      94% 83%,
      94% 56%,
      11% 56%,
      11% 64%,
      94% 64%,
      94% 70%,
      88% 70%,
      88% 32%,
      18% 32%,
      18% 96%,
      10% 96%,
      10% 62%,
      9% 62%,
      9% 84%,
      68% 84%,
      68% 50%,
      52% 50%,
      52% 55%,
      35% 55%,
      35% 87%,
      25% 87%,
      25% 39%,
      15% 39%,
      15% 88%,
      52% 88%
    );
  }

  30% {
    clip-path: polygon(
      0% 53%,
      93% 53%,
      93% 62%,
      68% 62%,
      68% 37%,
      97% 37%,
      97% 89%,
      13% 89%,
      13% 45%,
      51% 45%,
      51% 88%,
      17% 88%,
      17% 54%,
      81% 54%,
      81% 75%,
      79% 75%,
      79% 76%,
      38% 76%,
      38% 28%,
      61% 28%,
      61% 12%,
      55% 12%,
      55% 62%,
      68% 62%,
      68% 51%,
      0% 51%,
      0% 92%,
      63% 92%,
      63% 4%,
      65% 4%
    );
  }

  45% {
    clip-path: polygon(
      0% 33%,
      2% 33%,
      2% 69%,
      58% 69%,
      58% 94%,
      55% 94%,
      55% 25%,
      33% 25%,
      33% 85%,
      16% 85%,
      16% 19%,
      5% 19%,
      5% 20%,
      79% 20%,
      79% 96%,
      93% 96%,
      93% 50%,
      5% 50%,
      5% 74%,
      55% 74%,
      55% 57%,
      96% 57%,
      96% 59%,
      87% 59%,
      87% 65%,
      82% 65%,
      82% 39%,
      63% 39%,
      63% 92%,
      4% 92%,
      4% 36%,
      24% 36%,
      24% 70%,
      1% 70%,
      1% 43%,
      15% 43%,
      15% 28%,
      23% 28%,
      23% 71%,
      90% 71%,
      90% 86%,
      97% 86%,
      97% 1%,
      60% 1%,
      60% 67%,
      71% 67%,
      71% 91%,
      17% 91%,
      17% 14%,
      39% 14%,
      39% 30%,
      58% 30%,
      58% 11%,
      52% 11%,
      52% 83%,
      68% 83%
    );
  }

  76% {
    clip-path: polygon(
      0% 26%,
      15% 26%,
      15% 73%,
      72% 73%,
      72% 70%,
      77% 70%,
      77% 75%,
      8% 75%,
      8% 42%,
      4% 42%,
      4% 61%,
      17% 61%,
      17% 12%,
      26% 12%,
      26% 63%,
      73% 63%,
      73% 43%,
      90% 43%,
      90% 67%,
      50% 67%,
      50% 41%,
      42% 41%,
      42% 46%,
      50% 46%,
      50% 84%,
      96% 84%,
      96% 78%,
      49% 78%,
      49% 25%,
      63% 25%,
      63% 14%
    );
  }

  90% {
    clip-path: polygon(
      0% 41%,
      13% 41%,
      13% 6%,
      87% 6%,
      87% 93%,
      10% 93%,
      10% 13%,
      89% 13%,
      89% 6%,
      3% 6%,
      3% 8%,
      16% 8%,
      16% 79%,
      0% 79%,
      0% 99%,
      92% 99%,
      92% 90%,
      5% 90%,
      5% 60%,
      0% 60%,
      0% 48%,
      89% 48%,
      89% 13%,
      80% 13%,
      80% 43%,
      95% 43%,
      95% 19%,
      80% 19%,
      80% 85%,
      38% 85%,
      38% 62%
    );
  }

  1%,
  7%,
  33%,
  47%,
  78%,
  93% {
    clip-path: none;
  }
}
@keyframes movement {
  0% {
    top: 0px;
    left: -20px;
  }

  15% {
    top: 10px;
    left: 10px;
  }

  60% {
    top: 6px;
    left: -10px;
  }

  75% {
    top: -6px;
    left: 20px;
  }

  100% {
    top: 10px;
    left: 6px;
  }
}
@keyframes opacity {
  0% {
    opacity: 0.1;
  }

  5% {
    opacity: 0.7;
  }

  30% {
    opacity: 0.4;
  }

  45% {
    opacity: 0.6;
  }

  76% {
    opacity: 0.4;
  }

  90% {
    opacity: 0.8;
  }

  1%,
  7%,
  33%,
  47%,
  78%,
  93% {
    opacity: 0;
  }
}
@keyframes font {
  0% {
    font-weight: 100;
    color: #e52981;
    filter: blur(3px);
  }

  20% {
    font-weight: 500;
    color: #fff;
    filter: blur(0);
  }

  50% {
    font-weight: 300;
    color: #1bc7fb;
    /* color: var(--secondary-color); */
    filter: blur(2px);
  }

  60% {
    font-weight: 700;
    color: #fff;
    filter: blur(0);
  }

  90% {
    font-weight: 500;
    color: #dd2d7f;
    filter: blur(6px);
  }
}

.container-cyber .glitch span {
  animation: paths 5s step-end infinite;
  font-size: 3em;
}
.container-cyber .glitch::before {
  animation: paths 5s step-end infinite, movement 10s step-end infinite,
    opacity 5s step-end infinite, font 8s step-end infinite;
  font-size: 3em;
  text-shadow: 3px 3px red;
}

.container-cyber .glitch::after {
  animation: paths 5s step-end infinite, movement 8s step-end infinite,
    opacity 5s step-end infinite, font 7s step-end infinite;
  font-size: 3em;
  text-shadow: 3px 3px var(--secondary-color);
}

/* Media */
@media (max-width: 700px) {
  .container-cyber {
    width: 80%;
  }
  .container-cyber h2 {
    font-size: 5em;
  }
  .container-cyber p {
    font-size: 3em;
  }
  .container-cyber a span {
    font-size: 2em;
  }
}
@media (max-width: 500px) {
  .container-cyber {
    width: 90%;
  }
  .container-cyber h2 {
    font-size: 3.8em;
  }
  .container-cyber p {
    font-size: 2.8em;
  }
  .container-cyber a span {
    font-size: 2em;
  }
}
