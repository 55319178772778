.container-contact {
  background-color: #000;
  text-align: left;
  min-height: 97vh;
  padding: 1%;
}
.container-contact .text {
  width: 80%;
  margin: auto;
}
.container-contact .text h2 {
  font-size: 3em;
  color: var(--secondary-color);
  text-shadow: 2px 2px 1px var(--shadow-secondary-color);
  font-weight: bold;
}
.container-contact .text h2::before,
.container-contact .text h2::after {
  content: "<h2>";
  color: #c1c1c1;
  display: block;
  margin: 2% 0;
  margin-left: -4%;
  font-weight: 100;
  text-shadow: none;
}
.container-contact .text h2::before {
  content: "<h2>";
}
.container-contact .text h2::after {
  content: "</h2>";
}
.container-contact .text p {
  font-size: 1.53em;
  color: var(--light-color);
  font-weight: 600;
}
.container-contact .text p::before,
.container-contact .text p::after {
  content: "<p>";
  color: #c1c1c1;
  display: block;
  margin: 2% 0;
  margin-left: -4%;
  font-weight: 100;
}
.container-contact .text p::before {
  content: "</p>";
}
.container-contact .text p::after {
  content: "</p>";
}
.container-contact .links {
  width: 80%;
  margin: auto;
  padding: 6% 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.container-contact .links a {
  margin: 2%;
  color: var(--light-color);
  text-decoration: none;
  font-weight: 700;
}
.container-contact .links .icon {
  font-size: 3em;
  border-radius: 50%;
}
.container-contact .links .icon:hover {
  font-size: 3.4em;
  animation: paths 5s step-end infinite, font 7s step-end infinite;
}
.container-contact .links .icon.git:hover {
  color: var(--dark-color);
}
.container-contact .links .icon.linkedin:hover {
  color: #0e76a8;
}
