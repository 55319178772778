/*  */
.tag-up-html::before,
.tag-up-body::before {
  color: #c1c1c1;
  display: block;
  margin: 2% 0 0 4%;
  font-weight: 100;
  position: absolute;
}
.tag-up-html::before {
  content: "<html>";
  left: 0%;
  top: 0;
}
.tag-up-body::before {
  content: "<body>";
  top: 4%;
  left: 4%;
}
.container-tags {
  position: relative;
  color: #c1c1c1;
  width: 100%;
}
.tags {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: left;
}
.tag-body::after {
  content: "</body>";
  display: block;
  margin: 1% 8%;
}
.tag-html::after {
  content: "</html>";
  display: block;
  margin: 1% 4%;
}

/*  */
.container-home {
  height: 100vh;
  /* width: 100vw; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--dark-color);
}
.container-home .logo {
  flex: 1 1;
  z-index: 1;
}
.container-home .logo img {
  width: 100%;
  filter: drop-shadow(1px 1px 6px var(--secondary-color));
}
.container-home .title {
  flex: 1 1;
  text-align: left;
}
/* .container-home .title h1 {
  margin-left: 4%;
} */
.container-home .title h1::before,
.container-home .title h1::after {
  color: #c1c1c1;
  display: block;
  margin: 2% 2% 0 -4%;
  font-weight: 100;
}
.container-home .title h1::before {
  content: "<h1>";
}
.container-home .title h1::after {
  content: "</h1>";
}
.container-home .title h1 span {
  font-size: 5em;
  color: var(--light-color);
  transition: 0.4s ease-in;
}
.container-home .title h1 span:hover {
  animation: glitch 2.5s infinite;
  font-size: 6.3em;
}
.container-home .title p {
  font-size: 2em;
  color: #c1c1c1;
  font-weight: 600;
  /* margin-left: 4%; */
}
.container-home .title p::before,
.container-home .title p::after {
  display: block;
  margin: 2% 2% 0 -4%;
  font-weight: 100;
}
.container-home .title p::before {
  content: "<p>";
}
.container-home .title p::after {
  content: "</p>";
}
.container-home .title .btn-contact {
  margin-top: 6%;
}
.container-home .title .btn-contact a {
  font-size: 2em;
  color: var(--light-color);
  font-weight: 600;
  text-decoration: none;
  border: 2px solid var(--secondary-color);
  border-radius: 6px;
  padding: 2%;
  transition: 0.8s;
  z-index: 1;
  position: relative;
}
.container-home .title .btn-contact a::after {
  content: "";
  position: absolute;
  border: 2px solid var(--secondary-color);
  border-radius: 6px;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 6.6px;
  left: 6.6px;
  transition: 0.6s;
}
.container-home .title .btn-contact a:hover::after {
  top: 0px;
  left: 0px;
}
.container-home .title .btn-contact a::before {
  content: "";
  width: 0;
  position: absolute;
  background: var(--secondary-color);
  top: 0;
  bottom: 0;
  left: 0;
  transition: 0.6s;
  z-index: -1;
}
.container-home .title .btn-contact a:hover::before {
  width: 100%;
}
.container-home .title .btn-contact a:hover {
  text-shadow: 1px 1px 1px var(--dark-color);
  animation: glitch 5s infinite, glitch-box 5s infinite;
}
/* hr */
hr {
  color: var(--secondary-color);
  width: 94%;
  margin: auto;
}

/* Scroll down */
.arrow {
  position: absolute;
  bottom: 5%;
  right: 4%;
}
.arrow span {
  display: block;
  width: 10px;
  height: 10px;
  border-bottom: 6px solid var(--secondary-color);
  border-right: 6px solid var(--secondary-color);
  animation: font 16s infinite, paths 2s infinite, animate-arrow 1.5s infinite;
  transform: rotate(45deg);
  position: relative;
}
.arrow span::before,
.arrow span::after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  border-bottom: 6px solid var(--secondary-color);
  border-right: 6px solid var(--secondary-color);
  display: block;
}
.arrow span::before {
  margin: -10px;
}
.arrow span::after {
  margin: 10px;
}
@keyframes animate-arrow {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-10px, -10px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(10px, 10px);
  }
}
/* Up / Down */
.arrow-scroll {
  position: fixed;
  bottom: 5%;
  right: 4%;
  width: 10px;
  height: 10px;
  z-index: 1;
}
.arrow-scroll .arrow-icon {
  font-size: 2.2em;
  color: var(--secondary-color);
  cursor: pointer;
  transition: 0.8s;
}
.arrow-scroll .arrow-icon:hover {
  font-size: 2.6;
  color: var(--light-color);
}
.arrow-scroll .icon-rotate {
  transform: rotate(180deg);
}
.none {
  display: none;
}
/* Media */
@media (max-width: 800px) {
  .container-home {
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 4%;
  }
  .container-home .logo {
    flex: 0;
  }
  .container-home .logo img {
    width: 38%;
  }
  .container-home .title h1 span {
    font-size: 3.2em;
  }
  .container-home .title h1 span:hover {
    font-size: 3.7em;
  }
  .container-home .title p {
    font-size: 1.8em;
  }
  .container-home .title .btn-contact a {
    font-size: 1.8em;
  }
}
@media (max-width: 500px) {
  .container-home {
    align-items: center;
    justify-content: center;
  }
  .container-home .logo {
    position: absolute;
    z-index: 0;
  }
  .container-home .logo img {
    width: 100%;
    filter: blur(2px);
  }
  .container-home .title {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .container-home .title h1 span {
    font-size: 3em;
    text-shadow: 2px 2px 1px var(--dark-color);
  }
  .container-home .title h1 span:hover {
    font-size: 3.5em;
  }
  .container-home .title p {
    margin: 0;
    text-shadow: 2px 2px 1px var(--dark-color);
  }
  .container-home .title p::before,
  .container-home .title p::after,
  .container-home .title h1::before,
  .container-home .title h1::after {
    color: var(--light-color);
    text-shadow: 2px 2px 1px var(--dark-color);
    margin: 0;
  }
  .arrow-scroll {
    right: 6%;
  }
}

/*  */
.blk {
  background-color: var(--dark-color);
}
.zero {
  opacity: 0;
  transform: scale(0.7);
}
.one {
  opacity: 1;
  transition: 1s;
}
