.container-skills {
  background-color: var(--primary-color);
  text-align: left;
  min-height: 97vh;
  padding: 1%;
}
.container-skills .text {
  width: 80%;
  margin: auto;
}
.container-skills .text h2 {
  font-size: 3em;
  color: var(--secondary-color);
  text-shadow: 2px 2px 1px var(--shadow-secondary-color);
  font-weight: bold;
}
.container-skills .text h2::before,
.container-skills .text h2::after {
  content: "<h2>";
  color: #c1c1c1;
  display: block;
  margin: 2% 0;
  margin-left: -4%;
  font-weight: 100;
  text-shadow: none;
}
.container-skills .text h2::before {
  content: "<h2>";
}
.container-skills .text h2::after {
  content: "</h2>";
}
.container-skills .text p {
  font-size: 1.53em;
  color: var(--light-color);
  font-weight: 600;
}
.container-skills .text p::before,
.container-skills .text p::after {
  content: "<p>";
  color: #c1c1c1;
  display: block;
  margin: 2% 0;
  margin-left: -4%;
  font-weight: 100;
}
.container-skills .text p::before {
  content: "<p>";
}
.container-skills .text p::after {
  content: "</p>";
}
.container-skills .text a {
  font-family: La Belle Aurore, cursive;
  text-decoration: none;
  color: var(--light-color);
  padding: 1%;
  font-size: 1em;
  transition: 0.6s ease-in-out;
  cursor: none;
}
.container-skills .text a:hover {
  text-decoration: underline;
  animation: glitch 5s infinite;
  color: var(--secondary-color);
}
.skills {
  width: 72%;
  margin: 6% auto;
}
.bar {
  background-color: var(--light-color);
  padding: 0.5%;
  border-radius: 16px;
  margin: 2% 0;
}
.bar:hover {
  animation: glitch-box 2s infinite;
}
.bar::before {
  content: attr(data-skills);
  background: linear-gradient(
    to right,
    var(--secondary-color),
    var(--shadow-secondary-color)
  );
  font-family: Roboto, sans-serif;
  font-size: 1.1em;
  font-weight: bold;
  color: var(--light-color);
  text-shadow: 1px 1px 1px var(--dark-color);
  display: block;
  padding: 1.5%;
  border-radius: 16px;
  animation: load-bar 3.2s;
}
/* or porcent %...Cambiable */
.bar.basic::before {
  width: 30%;
}
.bar.intermediate::before {
  width: 60%;
}
.bar.advanced::before {
  width: 70%;
}
.bar.almost-expert::before {
  width: 90%;
}
@keyframes load-bar {
  from {
    width: 0%;
  }
}
