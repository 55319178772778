/* import */
@import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap");
/* Root */
:root {
  --primary-color: #1d1d1d;
  --secondary-color: #00fcd7;

  --light-color: #fff;
  --dark-color: #000;
  --shadow-secondary-color: #00c8a6;
}
/* General */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-size: 16px;
}
* {
  font-family: Roboto, sans-serif;
}
*::after,
*::before {
  font-family: La Belle Aurore, cursive;
}
body {
  width: 100%;
  height: 100vh;
  background-color: var(--primary-color);
  text-align: center;
  user-select: none;
}

/* Nav */
/* header {
  position: relative;
} */
.container-navigation .navigation-btn {
  display: none;
}
.container-navigation .navigation-menu {
  background-color: var(--light-color);
  height: 70px;
  width: 70px;
  transform: rotate(45deg);
  filter: drop-shadow(1px 1px 4px var(--shadow-secondary-color));
  position: fixed;
  top: 3em;
  right: 3em;
  z-index: 9999;
  text-align: center;
  cursor: pointer;
}
.container-navigation .navigation-menu:hover {
  filter: drop-shadow(1px 1px 6px var(--secondary-color));
}
.container-navigation .navigation-background {
  height: 70px;
  width: 70px;
  position: fixed;
  top: 3em;
  right: 3em;
  background-image: radial-gradient(
    var(--shadow-secondary-color),
    var(--primary-color)
  );
  z-index: 99;
  transition: 0.4s;
}
.container-navigation .navigation-menu:hover + .navigation-background {
  box-shadow: 1px 1px 6px var(--secondary-color);
}
.navigation-background::before {
  content: "";
  position: absolute;
  left: -69px;
  top: 1px;
  width: 0;
  height: 0;
  border-width: 35px;
  border-style: solid;
  border-color: transparent var(--shadow-secondary-color) transparent
    transparent;
  display: none;
}
.container-navigation .navigation-menu:hover + .navigation-background::before {
  display: block;
  filter: drop-shadow(1px 1px 6px var(--secondary-color));
}
.container-navigation .navigation-nav {
  height: 70px;
  position: fixed;
  right: 4em;
  top: 3em;
  z-index: 999;
  display: none;
  width: 0;
}
.container-navigation .navigation-nav ul {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  width: 100%;
  height: 100%;
}
.navigation-nav ul li {
  margin: 0 2%;
  text-align: center;
}
.navigation-nav ul li a {
  color: var(--light-color);
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2em;
  filter: drop-shadow(1px 1px 1px var(--primary-color));
}
.navigation-nav ul li:hover a {
  font-size: 1.26em;
  animation: glitch 5s infinite, paths 5s step-end infinite;
}
.navigation-nav ul li a:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0%;
  transform: translateX(-50%);
  height: 3px;
  background: var(--light-color);
  transition: 0.4s;
}
.navigation-nav ul li a:hover:after,
.navigation-nav ul li a:focus:after,
.navigation-nav ul li a:active:after {
  width: 100%;
}
.container-navigation .active-menu ~ .blur {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 90;
  backdrop-filter: blur(6px);
}
.container-navigation .active-menu ~ .navigation-background {
  width: 80%;
  right: 4.5em;
  background-image: none;
  background-color: var(--shadow-secondary-color);
}
.container-navigation
  .active-menu
  ~ .navigation-menu:hover
  + .navigation-background {
  animation: glitch-box 5s infinite, paths 5s step-end infinite;
}
.container-navigation .active-menu ~ .navigation-background::before {
  display: block;
}
.container-navigation .active-menu ~ .navigation-nav {
  display: block;
  width: 80%;
}
.navigation-menu span {
  position: relative;
  margin-top: 2em;
  transform: rotate(-45deg);
}
.navigation-menu span,
.navigation-menu span::before,
.navigation-menu span::after {
  width: 3em;
  height: 2px;
  background-color: var(--primary-color);
  display: inline-block;
}
.navigation-menu span::before,
.navigation-menu span::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.3s;
}
.navigation-menu span::before {
  bottom: 0.8em;
}
.navigation-menu span::after {
  top: 0.8em;
}
.navigation-menu:hover span::before {
  bottom: 1em;
}
.navigation-menu:hover span::after {
  top: 1em;
}
.container-navigation .active-menu + .navigation-menu {
  animation: glitch-box 5s infinite, paths 5s step-end infinite,
    font 8s step-end infinite;
}
.container-navigation .active-menu + .navigation-menu span {
  background-color: transparent;
}
.container-navigation .active-menu + .navigation-menu span::before {
  top: 0;
  transform: rotate(135deg);
}
.container-navigation .active-menu + .navigation-menu span::after {
  top: 0;
  transform: rotate(-135deg);
}

/* media */
@media (max-width: 800px) {
  .container-navigation .navigation-menu {
    top: 1.5em;
    right: 1.5em;
  }
  .container-navigation .navigation-background {
    top: 1.5em;
    right: 1.5em;
  }
  .container-navigation .navigation-nav {
    top: 1.5em;
  }
  .container-navigation .navigation-nav ul {
    /* margin-top: -25%; */
    margin-top: -20%;
  }
}
@media (max-width: 700px) {
  .container-navigation {
    position: fixed;
    z-index: 9999;
  }
  .container-navigation .navigation-menu {
    top: 2em;
    right: 2em;
  }
  .container-navigation .navigation-background {
    height: 70px;
    width: 70px;
    top: 2em;
    right: 2em;
    transition: transform 0.8s;
    transform: rotate(45deg);
  }
  .container-navigation .active-menu ~ .navigation-nav ul li {
    animation: fadeIn 0.6s forwards;
    animation-delay: 0.35s;
    position: relative;
    opacity: 0;
  }
  .container-navigation .active-menu ~ .navigation-nav ul li:nth-child(2) {
    animation-delay: 0.4s;
  }
  .container-navigation .active-menu ~ .navigation-nav ul li:nth-child(3) {
    animation-delay: 0.45s;
  }
  .container-navigation .active-menu ~ .navigation-nav ul li:nth-child(4) {
    animation-delay: 0.5s;
  }
  .container-navigation .active-menu ~ .navigation-nav ul li:nth-child(5) {
    animation-delay: 0.55s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
      left: 20%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }
  .container-navigation .navigation-nav {
    right: 0;
    width: 100%;
    height: 100%;
  }
  .container-navigation .navigation-nav ul {
    flex-direction: column;
  }
  .container-navigation .navigation-nav ul li {
    margin: 3% 0;
    font-size: 1.5em;
  }
  .container-navigation .active-menu ~ .navigation-background {
    transform: scale(150);
    background-image: radial-gradient(
      var(--shadow-secondary-color),
      var(--primary-color)
    );
  }
  .container-navigation
    .active-menu
    ~ .navigation-menu:hover
    + .navigation-background {
    animation: none;
  }
  .container-navigation .active-menu ~ .navigation-nav {
    width: 100vw;
    height: 100vh;
  }
  .container-navigation
    .navigation-menu:hover
    + .navigation-background::before {
    display: none;
  }
}

/* Footer */
/* footer {
  display: flex;
  justify-content: center;
}
.container-footer {
  background-color: var(--shadow-secondary-color);
  height: 40px;
  width: 50%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px 60px 0 0;
}
.container-footer p {
  font-size: 1.5em;
  font-weight: bold;
  text-shadow: 1px 1px 1px #000;
  color: var(--light-color);
} */
